class Dropdown {
  constructor(element) {
    this.element = element;
    this.init();
  }

  init() {
    this.menu = this.element.querySelector('.dropdown-menu');

    const openCb = this.open.bind(this);
    const closeCb = this.close.bind(this);

    this.element.addEventListener('click', openCb);
    document.addEventListener('click', closeCb);
  }

  close() {
    if (!this.menu.classList.contains('show')) {
      return;
    }
    this.element.setAttribute('aria-expanded', false);
    this.menu.classList.remove('show');
  }

  open(e) {
    if (this.menu.classList.contains('show')) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.element.setAttribute('aria-expanded', true);
    this.menu.classList.add('show');
  }
}

export default Dropdown;

document.addEventListener('DOMContentLoaded', () => {
  const elements = document.querySelectorAll('[data-kjb-element="dropdown"]');
  if (!elements) {
    return;
  }

  Array.prototype.forEach.call(elements, element => new Dropdown(element));
});
